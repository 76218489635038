import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';

function Termdeposit() {
      return(
        

        <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_294_term-deposit.jpg" style={{width: '100%'}}/>
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_294_term-deposit_s.jpg" alt="Term-Deposit" style={{width: '100%'}}/>
        
        
    </div>
   


    <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="2" />
                <li><Link to="index.html">Home</Link></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0">
                            <Link to='/personal'>Personal</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_1" class="active">
                            <Link to='/termdeposit'>Term Deposit</Link></li>
                    

            </ul>
        </div>
    </div>
   



    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                   


                    <div class="page_header">
                        <h1>
                            Term Deposit</h1>

                    </div>
                    Ecofin offers interest at half-yearly, quarterly and monthly basis to make sure you receive more on your savings with Interest Payout Savings Scheme. Choose an option that suits your needs, sit back and enjoy the benefits.

                    

                    


    <div class="awards">
        <div class="row">
            
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                              <Link to="/termdeposit/fixeddeposit">
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/az.png" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>Let your savings multiply actively with our Fixed Deposit schemes.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Fixed  Deposits</h5>
                                    </div>
                                    
                                </div>
                                </Link>
                                    
                            </div>
                        
                    </div>
                
                
                


        </div>
    </div>
 </div>










                </div>


            </div>

        </div>

    </div>



    



        
    





      )

}

export default Termdeposit