import React from 'react';
import Body from './Body';



function Home(){
	return(
	
	<Body />
	
	
	);

}

export default Home;