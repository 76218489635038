import React,{useEffect} from "react";
import Personal from'./Personal';
import ReactDOM from "react-dom";
import tawkTo from "tawkto-react";
import { Router, Routes, Route, Link, Navigate,Outlet } from "react-router-dom";
//import {Switch} from 'react-router-dom';
import Hcontent from './Hcontent';
import CurrentAccount from './CurrentAccount';
import MobileBanking from './MobileBanking';
import Termdeposit from'./Termdeposit';
import Retailloan from './Retailloan';
import Fixeddeposit from './Fixeddeposit';
import Educationloan from './Educationloan';
import Cards from'./Cards';
import ScrollToTop from './ScrollToTop';


import Savings from './Savings';
import Businessloan from './Businessloan';

function Body() {

return (





		
<div >

	

    <div class="wsmenucontainer clearfix">
        <div class="overlapblackbg">
        </div>
        
   








      <Hcontent />
   



   <Routes>

         
          
           <Route   exact path="/"  element={ <Navigate replace to='/index.html' />} >
        
           </Route>
           <Route   exact path="/personal"  element={ <Personal />} >
        
           </Route>
           

           
           
           
           <Route   exact path="/mobilebanking"  element={ <MobileBanking />} >
        
           </Route>
           
           
           <Route   exact path="/savings"  element={ <Savings />} >
        
           </Route>

           <Route    path="/termdeposit"  element={ <Termdeposit />} >
        
           </Route>
           <Route    path="/termdeposit/fixeddeposit"  element={ <Fixeddeposit />} >
        
           </Route>


            <Route   path="/retailloan"  element={ <Retailloan />} >
        
           </Route>
            <Route   path="/retailloan/educationloan"  element={ <Educationloan />} >
        
           </Route>

           <Route   exact path="/prepaidcards"  element={ <Cards />} >
        
           </Route>
 
          
          </Routes>


          <Routes>


    


           <Route   exact path="/currentaccount"  element={ <CurrentAccount />} >

        
           </Route>

           <Route   path="/businessloans"  element={ <Businessloan />} >
        
           </Route>

          </Routes>
                    
            









        
    
    <div id="MainContent_sectionSlider" class="slider">
        


    

        <div id="carousel-example-generic" class="carousel slide desktop_slide" data-ride="carousel">
            <div class="carousel-inner">
                
                <div class="item active">
                    <Link to="/retailloan">
                       <img class="img-responsive" src="./images/1.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4><span></span><br/>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/termdeposit">
                         <img class="img-responsive" src="./images/2.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/retailloan/educationloan">
                          <img class="img-responsive" src="./images/3.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
               
               
                <div class="item">
                    <Link to="/retailloan">
                          <img class="img-responsive" src="./images/4.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/index.html">
                         <img class="img-responsive" src="./images/wb6.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                
                <div class="item">
                    <Link to="/currentaccount">
                         <img class="img-responsive" src="./images/wb7.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/termdeposit/fixeddeposit">
                        <img class="img-responsive" src="./images/5.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
               
            </div>
           

         








            <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                <span class="glyphicon glyphicon-chevron-left"></span></a><a class="right carousel-control"
                    href="#carousel-example-generic" role="button" data-slide="next"><span class="glyphicon glyphicon-chevron-right">
                    </span></a>
        </div>
       



























        <div id="carousel-example-generic-Mobile" class="carousel slide mobile_slide" data-ride="carousel">
            <div class="carousel-inner">
                
                <div class="item active">
                    <Link to="/retailloan">
                       <img class="img-responsive" src="./images/1.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4><span></span><br/>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/termdeposit">
                         <img class="img-responsive" src="./images/3.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/retailloan/educationloan">
                          <img class="img-responsive" src="./images/2.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/prepaidcards">
                          <img class="img-responsive" src="./images/5.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
               
                <div class="item">
                    <Link to="/retailloan">
                          <img class="img-responsive" src="./images/wb6.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                
                
                <div class="item">
                    <Link to="/currentaccount">
                         <img class="img-responsive" src="./images/wb7.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
                <div class="item">
                    <Link to="/mobilebanking">
                        <img class="img-responsive" src="./images/4.png" alt=""/></Link>
                    <div class="carousel-caption">
                        <h4>
                            </h4>
                    </div>
                </div>
               
            </div>




















           
            <a class="left carousel-control" href="#carousel-example-generic-Mobile" role="button"
                data-slide="prev"><span class="glyphicon glyphicon-chevron-left"></span></a>
            <a class="right carousel-control" href="#carousel-example-generic-Mobile" role="button"
                data-slide="next"><span class="glyphicon glyphicon-chevron-right"></span></a>
        </div>
        

















        <div id="MainContent_sectionQNP" class="sideright">
            <h2 style={{display: 'none'}}>
                </h2>
            <div class="row">
                
                
                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-6">
                    <div class="side_text">
                        <Link to='/currentaccount'>
                            <img src='images/bag.png' title="" class="side_static" alt="" />
                            <img src='images/bag.png' class="side_hover" title="" alt="" />
                            <h5>
                                 Current account</h5>
                        </Link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-6">
                    <div class="side_text">
                        <Link to='/savings'>
                            <img src='images/Sv.png' title="" class="side_static" alt="" />
                            <img src='images/Sv.png' class="side_hover" title="" alt="" />
                            <h5>
                                 Savings account</h5>
                        </Link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-6">
                    <div class="side_text">
                        <Link to='/prepaidcards'>
                            <img src='images/card.png' title="" class="side_static" alt="" />
                            <img src='images/card.png' class="side_hover" title="" alt="" />
                            <h5>
                                Prepaid Cards</h5>
                        </Link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-3 col-xs-6">
                    <div class="side_text">
                        <Link to='/mobilebanking'>
                            <img src='images/car.png' title="" class="side_static" alt="" />
                            <img src='images/car.png' class="side_hover" title="" alt="" />
                            <h5>
                                Mobile Banking</h5>
                        </Link>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="looking_search">
                        <div>
                            <input name="ctl00$MainContent$txtSearch" type="text" id="MainContent_txtSearch" class="form-control" placeholder="I&#39;m looking for..." />
                            <span id="MainContent_rpt" style={{visibility:'hidden'}}></span>
                            
                            <a id="MainContent_lnkSearch" class="btn" href=""><i class="fa fa-search atmi"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <a id="MainContent_locateUs" class="locate_btn" href="">Locate Us</a>
        </div>
       
    </div>
    
         </div>







    
    <div id="MainContent_sectionTicker" class="highlights_slider">
        <div class="container">
            <marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();">
                    

                           

                                <a href=''>
                                    Change in email address requests will only be accepted by filling the 'Digital Registration form'
                                </a>
                                   
                        

                           

                                <a href='#'>
                                    IMPORTaNT NOTE : Aadhaar � PAN aing date has been extended to  March 31st, 2022.
                                </a>
                                   
                        

                           

                                <a href='#'>
                                    We offer CCIL FX-RETAIL Trading Platform for Foreign Exchange transactions.
                                </a>
                                   
                        

                           

                                <a href='#'>
                                    Prevent Unauthorised Transactions in your Demat account. Update your Mobile Number with your Depository Participant. Receive alerts on your Registered Mobile for all debit and other important transaction in your demat account directly from NSDL /CDSL on the same day..issued in the interest of investors.
                                </a>
                                   
                        

                           

                                <a href='#'>
                                    ATTENTION INVESTORS - No need to issue cheques by investors while subscribing to IPO. Just write the bank account number and sign in the application form to authorise your bank to make payment in case of allotment. No worries for refund as the money remains in investor's account.
                                </a>
                                   
                        

                           

                                <a href='#'>
                                    KYC is one time exercise while dealing in securities markets - once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund etc.), you need not undergo the same process again when you approach another intermediary.
                                </a>
                                   
                        

                           

                                <a href=''>
                                    Fake Message on Recruitment  - The bank has noticed unreliable and erroneous messages being circulated via Whatsapp and few other recruiting sites by unidentified agencies or individuals, inviting applicants to apply for various posts. The bank DOES NOT advertise for recruitments through agents or agencies. Date- 16.02.2019
                                </a>
                                   
                        

    </marquee>
        </div>
    </div>
   













    <section id="MainContent_sectionRatePanel" class="rate_panel">
        <div class="container">
            <div class="row">
                
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="panel with-nav-tabs panel-default">
                                <div class="panel-heading">
                                    <ul class="nav nav-tabs">
                                        <li class="active">Education Loan</li>
                                        <li>UPTO 3 YEARS,W.E.F. 08.03.2021</li>
                                    </ul>
                                </div>
                                <div class="panel-body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade in active" id="tab1default">
                                            <h2>7.00%*</h2>
                                            <p>Get into your dream university with the lowest  loan rates</p>

                                            <Link to="/retailloan/educationloan">KNOW MORE</Link>
                                            

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="panel with-nav-tabs panel-default">
                                <div class="panel-heading">
                                    <ul class="nav nav-tabs">
                                        <li class="active">Fixed Deposit </li>
                                        <li>W.E.F. 07.10.2021</li>
                                    </ul>
                                </div>
                                <div class="panel-body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade in active" id="tab1default">
                                            <h2>6.50%*</h2>
                                            <p>With the lowest home loan rates, own a house and make it your home.*CIBIL Score >= 750</p>

                                            <Link to="/termdeposit/fixeddeposit">KNOW MORE</Link>
                                            
                                            

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="panel with-nav-tabs panel-default">
                                <div class="panel-heading">
                                    <ul class="nav nav-tabs">
                                        <li class="active">Scan QR Code</li>
                                        <li>Open Scanner</li>
                                    </ul>
                                </div>
                                <div class="panel-body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade in active" id="tab1default">
                                            <h2>8.00%*</h2>
                                            <p>Take benefits from your existing property and avail a multi-purpose loan against it.</p>

                                            <a id="MainContent_rptRatePanel_hypera1_2" class="learn_more hover_effect" href="product-details.aspx?id=Kwik-LaP">KNOW MORE</a>
                                            <input type="hidden" name="ctl00$MainContent$rptRatePanel$ctl02$hidForm" id="MainContent_rptRatePanel_hidForm_2" />
                                           

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    

            </div>
        </div>
    </section>
   








      <section id="MainContent_sectionDigiBanking" class="digital_banking">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="list-group digital_links mmobile_digitallinks">
                        

                        <Link to='/mobilebanking' class="list-group-item text-center">
                        <img src='images/gomo.png' class="img-responsive" title="" alt="" />
                        <h5>Mobile Banking</h5></Link>

                        <Link to='/personal' class="list-group-item text-center">
                        <img src='images/per.png' class="img-responsive" title="" alt="" />
                        <h5>Personal  Banking</h5></Link>
                        
                        <Link to='/businessloans' class="list-group-item  text-center">
                        <img src='images/busi.jpg' class="img-responsive" title="" alt="" />
                        <h5>Business Loan</h5></Link>

                        <a href='' class="list-group-item text-center">
                        <img src='images/down.png' class="img-responsive" title="" alt="" />
                        <h5>Download  App</h5></a>

                     

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="bhoechie-tab-container">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 bhoechie-tab-menu">
                        <div class="list-group digital_links mdesktop_digitallinks">

                            
                        <Link to='/mobilebanking' class="list-group-item  text-center">
                        <img src='images/gomo.png' class="img-responsive" title="" alt="" />
                        <h5>Mobile Banking</h5></Link>



                       

                     

                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 bhoechie-tab">
            

                        

                        <div class="bhoechie-tab-content active digital_text">
                            <h2>Mobile Banking</h2>
                            <p>Gone are the days when you had to visit the bank for all your monetary transactions. EcoFin Mobile App helps you manage your 
                            bank account effortlessly.<br /><br /> A freedom from conventional banking, the EcoFin Bank Mobile App allows you to transfer fund while on the go.</p>
                            <a href="">Click here to Download App</a>
                        </div>

                       

                    </div>
                </div>
            </div>
        </div>

    </section>











    <section id="MainContent_sectionNews" class="news">
        <div class="container">
            <div class="news_header">
                
                <h2 style={{color: '#333'}}>
                <a id="MainContent_hypNewsHead1" href="">News & Updates</a>
                </h2>
                <p>
                    Get latest updates, tips, and how-to information on various banking topics
                </p>
            </div>

           
                    
            
           
        </div>
    </section>
  






      <section id="MainContent_sectionFuturePlans" class="goals">
        <div class="container">
            <img src="images/xz2.jpg" class="future_plan" alt="" title="" />
            <div class="row">
                <div class="col-lg-7 col-md-8 col-sm-12 col-xs-12 pull-right">
                    <div class="goal_tabs">
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-sm-5 col-xs-12">
                                
                            </div>

                            
                                    <div class="col-lg-8 col-md-7 col-sm-7 col-xs-12 well admin-content" id='18'>
                                        <h2>Educational Loan <span>Loan Description</span></h2>
                                        <p>
                                   Good education is the greatest gift you can give your child. EcoFin enthusiastically backs parents and students to promote effective education. Opt from these loan schemes to make sure your child gets the right guidance to become a good human.

                                           
                                        </p>





                                        

                                    </div>
                                
                                   
                                


                        </div>
                    </div>
               </div>
            </div>
            </div>
    </section>



    
    
   







    

        



      <div class="healp_desk">
            <div class="container">
                <ul>
                    
                            <tr>
                                <td>
                                    <li><a href='https://www.india.gov.in/calendar?a=1630972800083'>
                                       <h4><b> Calendar 2021</b></h4></a></li>
                                </td>
                            </tr>
                        
                        
                </ul>
            </div>
        </div>





      
        <footer>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            
                                    <a id="anc" data-src="9" data-toggle="modal" data-target="#RBIRedictRBIKehtaHai" style={{cursor:'pointer'}}><h4>RBI Kehta Hai</h4></a>
                                   
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                            <h4>Follow Us On</h4>
                            <ul>
                                 <a href='' target="_blank">
                                            <img src='images/Whatsapp Logo website.png' title='Bank on Whatsapp' alt='' />&nbsp;&nbsp;
                                        </a>
                                    
                                        <a href='' target="_blank">
                                            <img src='images/fB LOGO WEBSITE.png' title='Facebook' alt='' />&nbsp;&nbsp;
                                        </a>
                                    
                                        <a href='' target="_blank">
                                            <img src='images/Twitter website logo.png' title='Twitter' alt='' />&nbsp;&nbsp;
                                        </a>
                                    
                                        <a href='' target="_blank">
                                            <img src='images/insta website logo.png' title='Instagram' alt='' />&nbsp;&nbsp;
                                        </a>
                                    
                                        <a href='' target="_blank">
                                            <img src='images/Youtube website logo.png' title='Youtube' alt='' />&nbsp;&nbsp;
                                        </a>
                                    
                                        <a href='' target="_blank">
                                            <img src='images/lniked-in.png' title='aedIn' alt='' />&nbsp;&nbsp;
                                        </a>
                                <li></li>
                            </ul>
                        </div>

                        <div class="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                         
                            <div class="social">
                              
                                
                                       
                                    
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            <h4>EcoFin</h4>
                            
                                    <p style={{textalign:'left'}}>  
                                        <b>Corporate Office</b>
                                        <br />
                                      Ecofin Credit Souharda Co-op Ltd.
                                        <br />
                                     Veerabhadreshwara Krupa, Siddheshwar Park Circle,
                                     Sirur Park Double Road,
                                     Vidyanagar, Hubli � 580 031

                                        <br />
                                        Tel:  +91-91480 80555
                                        <br />
                                        Email:  ecofinubl@gmail.com
                                    </p>
                                

                        </div>
                    </div>
                    <div class="copyright">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <p>
                                    <span id="lblCopyright">� 2021 Ecofin. all Rights Reserved.</span>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <ul>
                                    
                                            <li><a href=''>Disclaimer</a></li>
                                        
                                            <li><a href=''>Privacy Policy</a></li>
                                        
                                            <li><a href=''>Sitemap</a></li>
                                        
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </footer>
      









    <div class="modal fade" id="RBIRedictRBIKehtaHai" tabindex="-1" role="dialog" aria-labelledby="modalLabel"aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">x</span><span class="sr-only">Close</span>
                    </button>
                    <h3 class="modal-title" id="H1">
                        Disclaimer
                    </h3>
                
                         <div class="modal-body">
                    <h4>
                        <strong>REDIRECTION TO THIRD PARTY WEBSITE</strong></h4>
                    This website contains third party as. after clicking on these as you will
                    be leaving EcoFin website. These as are provided for your information
                    and convenience. EcoFin does not endorse nor has any control over third party
                    website content.&nbsp; EcoFin is not responsible for the content or availability
                    of third party websites.<br/>
                    <br/>
                    Therefore EcoFin makes no warranties or representation, express or implied&nbsp;
                    about such aed websites or information contained on them.<br/>
                    <br/>
                    Third party websites may have different privacy and security policies than EcoFin
                    . You should review the privacy and security policies of any third party websites
                    before you provide personal or confidential information.
                    <br/>
                    <a id="ancred" class="locate_btn" href="https://m.rbi.org.in/commonperson/English/Scripts/rbikehtahai.aspx"
                        target="_blank">Redirect to RBI Kehta Hai</a>
                    <div style={{clear: 'both'}}></div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    
    
   




   
    <div class="modal fade" id="apply_now" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">x</span><span class="sr-only">Close</span></button>
                    <h3 class="modal-title" id="lineModalLabel">
                        apply for
                        <label id="lblLoanName" style={{fontweight: '100'}}>
                        </label>
                    </h3>
                </div>







                <div class="modal-body">
                    
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <input type="hidden" name="hiddenValue" id="hiddenValue" value="" />
                                <input type="hidden" name="hiddenValue" id="hidden1" value="" />
                                <input type="hidden" name="hiddenValue" id="hidden2" value="" />
                                <input type="hidden" name="hiddenValue" id="hidden3" value="" />
                                <span><i class="fa fa-user" aria-hidden="true"></i></span>
                                <input name="ctl00$txtName" type="text" maxlength="50" id="txtName" class="form-control" placeholder="Name" />
                                <span id="rfv1" style={{color:"Red",display:"none"}}>*</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <span><i class="fa fa-phone" aria-hidden="true"></i></span>
                                <input name="ctl00$txtMobile" type="text" maxlength="10" id="txtMobile" class="form-control" placeholder="Mobile" onkeypress="return IsNumeric(event);" ondrop="return false;" onpaste="return false;" />
                                <span id="RequiredFieldValidator4" style={{color:"Red",display:"none"}}>*</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <span><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                <input name="ctl00$txtEmail" type="text" maxlength="60" id="txtEmail" class="form-control" placeholder="Email" />
                                <span id="RequiredFieldValidator1" style={{color:"Red",display:"none"}}>*</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{display: 'none'}}>
                            <div class="form-group">
                                <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <input name="ctl00$txtaddress" type="text" maxlength="100" id="txtaddress" class="form-control" placeholder="address" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <span><i class="fa fa-map" aria-hidden="true"></i></span>
                                <select name="ctl00$ddlStateMain" id="ddlStateMain" class="form-control">

</select>
                                <span id="RequiredFieldValidator3" style={{color:"Red",display:"none"}}>*</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <span><i class="fa fa-building" aria-hidden="true"></i></span>
                                <select name="ctl00$ddlCityMain" id="ddlCityMain" class="form-control">

</select>
                                <span id="RequiredFieldValidator5" style={{color:"Red",display:"none"}}>*</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                                <span><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                <select name="ctl00$ddlBranchMain" id="ddlBranchMain" class="form-control">

</select>
                                <span id="RequiredFieldValidator6" style={{color:"Red",display:"none"}}>*</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style={{display: 'none'}}>
                            <div class="form-group">
                                <span><i>
                                    <img src="images/pin.png" title="" alt="" /></i></span>
                                <input name="ctl00$txtPincode" type="text" maxlength="6" id="txtPincode" class="form-control" placeholder="Pincode" onkeypress="return IsNumeric(event);" ondrop="return false;" onpaste="return false;" />
                            </div>
                        </div>
                    </div>
                    <span id="error" style={{color: 'Red'}} class="pull-left">* Input digits
                        (0 - 9)</span>
                    <span id="rvDigitsssss" class="requiredtext" >Please enter the valid mobile no</span><br/>
                    
                    <a id="btnSubmitForm" class="btn btn-default pull-right">Submit</a><br/>
                </div>
            </div>
        </div>
    </div>
   





    




    
		</div>
        
		
		
		);

        }



export default Body

