import React from 'react';
import {Routes,Route,Link,Outlet} from 'react-router-dom';

function Educationloan() {
      return(
        


    




      
        <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_40_personal.jpg" style={{width: '100%'}} />
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_40_personal_s.jpg" alt="Personal" style={{width: '100%'}} />
        
        
    </div>
   


        <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="3" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0">
                            <Link to='/Personal'>Personal</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_1">
                            <Link to='/Retailloans'>Retail Loans</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_2" class="active">
                            <Link to='/Educationloans'>Education Loans</Link></li>
                    

            </ul>
        </div>
    </div>
   



    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                   


                    <div class="page_header">
                        <h1>
                            Educational Loans</h1>

                    </div>
                        Good education is the greatest gift you can give your child. EcoFin enthusiastically backs parents and students to promote effective education. Opt from these loan schemes to make sure your child gets the right guidance to become a good human.

                    

                    
                        
                        <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <Link to ="/retailloan/educationloan">
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/Thumbnail_298_16---education-loans_s.jpg" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>Good education is the greatest gift you can give your child.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Education Loans</h5>
                                    </div>
                                </div>
                                    </Link>
                                    <Outlet />
                            </div>

                        
                        
                    </div>
                
                
                


        </div>
    </div>
 </div>










                </div>


         

        


</div>


      )

}

export default Educationloan