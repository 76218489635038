import React from 'react';
import {Routes,Route,Link,Outlet} from 'react-router-dom';

function Retailloan() {
      return(
        


    




      
        <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_294_term-deposit.jpg" style={{width: '100%'}}/>
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_294_term-deposit_s.jpg" alt="Term-Deposit" style={{width: '100%'}}/>
        
        
    </div>
   


    <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="2" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0">
                            <Link to='/personal'>Personal</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_1" class="active">
                            <Link to='/retailloan'>Retail Loan</Link></li>
                    

            </ul>
        </div>
    </div>
   



    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                   


                    <div class="page_header">
                        <h1>
                        Retail Loans</h1>

                    </div>
                        EcoFin  offers various loans to its customers. Bank takes care of its customers by offering comprehensive loan packages at extremely competitive rates, without any hidden costs. Our personalised service coupled with state of the art technology at absolutely market competitive rates shall make your Banking experience a truly memorable one.

                    

                    


                        <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <Link to ="/retailloan/educationloan">
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/Thumbnail_298_16---education-loans_s.jpg" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>Good education is the greatest gift you can give your child.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Education Loans</h5>
                                    </div>
                                </div>
                                    </Link>
                                    <Outlet />
                            </div>
                        
                    </div>
                
                
                


        </div>
    </div>
 </div>










                </div>


            </div>

        





      )

}

export default Retailloan