import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';

function MobileBanking() {
      return(
        

        <div >

    





                                
              
        
    

     <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_40_personal.jpg" style={{width: '100%'}} />
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_40_personal_s.jpg" alt="Personal" style={{width: '100%'}} />
        
        
    </div>
 


	
    <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="1" />
                <li><a href="index.aspx">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0" class="active">
                            <a href='navigation.aspx?id=Personal'>Personal</a></li>
                    

            </ul>
        </div>
    </div>




    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">

                    


             <div class="page_header">
        <h1>
             Mobile Banking</h1>

    </div>



              <div class="nri">
         <p>
	<span style={{fontsize:'10.5'}}><span style={{fontfamily:"sans-serif"}}>&ldquo;Mobile Banking&rdquo; is a comprehensive Mobile Banking platform that will enable customers to manage your own accounts with incredible ease from wherever you have mobile connectivity. The product will give you greater ease of use and a higher degree of convenience through an intuitive and design rich interface.</span></span><br />
	&nbsp;<br />
	<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}> Mobile Banking Facility of EcoFin lets you check the account balance, view mini statement, transfer funds within or outside  request Cheque Book, initiate Stop Payment instructions, Hotlist Card and more. To use the facility, you just need your Customer Id, Login Pin and Transaction Pin.</span></span><br />
	<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>You can transact upto Rupees Two Lakhs only per day.</span></span><br />
	&nbsp;<br />
	<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>The Bank has taken special efforts to ensure the safety of your accounts by implementing two factor authentications along with OTP concept for transactions above Rupees Five Thousand as per RBI guidelines.</span></span></p>


	
<h4>
	<strong><span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Features of  Mobile Banking:</span></span></strong></h4>
<ol>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Register yourself by entering your Net Banking&nbsp;credentials or Debit card details</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Instant &amp; 24X7 funds transfer service through IMPS</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Fund transfer within your own account, Intra bank or Interbank electronic fund transfer</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Maximum limit for fund transfer is up to Rs. 2 lakhs</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Check balances of your savings, current, Overdraft accounts</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>View mini statement</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Funds transfer to Mobile Number, to account number &amp; through NEFT</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Schedule your fund transfer through NEFT for future date &amp; time</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Addition / deletion of the payee</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Scan and pay through Bharat QR code</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Create &amp; view Term/ Recurring Deposit</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>View Rate of Interest for Term / Recurring Deposit</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Loan account details &amp; statement for the same</span></span></li>
	<li>
		<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Account Statement on email id registered with the Bank</span></span></li>
	<li>
		<strong><span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Card Services -</span></span></strong>
		<ul>
			<li>
				Debit Card Pin Generation</li>
			<li>
				Generate Digital Debit Card</li>
			<li>
				Cardless Cash Withdrawal</li>
			<li>
				Block Unblock Debit card</li>
		</ul>
	</li>
	<li>
		<strong>Various Value-Added Services -</strong>
		<ul>
			<li>
				&nbsp;Search IFSC code for other banks</li>
			<li>
				<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Chequebook request</span></span></li>
			<li>
				<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Cheque status inquiry</span></span></li>
			<li>
				<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Stop Payment of Cheque</span></span></li>
			<li>
				<span style={{fontfamily:"sans-serif"}}><span style={{fontsize:'10.5'}}>Account statement on Email</span></span></li>
		</ul>
	</li>
</ol>
<br />
<br />
<ul>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
	<li>
	</li>
</ul>
<br />
<br />
<h4 style={{fontfamily: " sans-serif"}}>
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
	<br />
</h4>
<div class="demat_benefits">
	<div class="demat_text">
		<h4>
			Note:&nbsp;</h4>
		<ol>
			<li>
				To know&nbsp;your Customer Id give a missed call on <a href="">00000000</a></li>
			<li>
				To activate your Digital Debit Card for online / ecom transactions, send keyword as ONLINE&lt;space&gt;last 4 digits&nbsp;of the Debit Card to 0000. Example - <strong>ONLINE 1234</strong></li>
		</ol>
	</div>
</div>
<br />
<h4 style={{fontfamily:" sans-serif" }} >
	Download App</h4>
<a href="https://play.google.com/store/apps/details?id=com.sil.ecofin" target="_blank">Get  for Android</a><br />
<a href="" target="_blank">Get  for iOS</a><br />

      
        <div class="accordion">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                


            </div>
        </div>


        
     
    </div>
     

                   
                </div>










                </div>


            </div>

        </div>

    </div>



    



        
    
	




      )

}

export default MobileBanking