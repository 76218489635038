import React, { useEffect } from "react";
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import Home from  './components/Home';
;

function App() {
 

  return (
  
   <Home />

  );
}


export default App;
 

