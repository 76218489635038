import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';

function Savings() {
      return(
        

        <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_40_personal.jpg" style={{width: '100%'}} />
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_40_personal_s.jpg" alt="Personal" style={{width: '100%'}} />
        
        
    </div>
<div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="3" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0">
                            <Link to='/personal'>Personal</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_1">
                            <Link to='/savings'>Savings</Link></li>
                    
                        

            </ul>
        </div>
    </div>
    



    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">


                    


                     <div class="page_header">
            <h1>Savings Account</h1>
              
          </div>
          


    <div class="compare_loans">


     

        
                <div class="compareloan_details">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="main_head">
                                <h4>Regular Savings Account</h4>
                               


                               <div class="compare">
                                    <div class="checkbox">
                                       <input type="hidden" name="ctl00$ctl00$MainContent$innerContent$rptCompareLoans$ctl00$hidCompare" id="MainContent_innerContent_rptCompareLoans_hidCompare_0" value="1" />
                                      
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand1_0" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="compareloan_text" >
                                        <h5>Eligibility:</h5>
                                       
                                           Our Savings Account is for anyone who is a resident individual (sole or joint) or comes from a Hindu undivided family. A Trust &amp; Foundation can also open a Savings Account.<br />

                                         
                                    </div>
                                </div>
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand2_0" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                   <div class="compareloan_text" >
                                        <h5>Features:</h5>
                                       
                                           <ol>
	<li>
		Available for individuals or organisations</li>
	<li>
		Free Rupay Debit card for 1st year</li>
	<li>
		20 Cheque leaves free per financial year</li>
</ol>

                                         
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                
                            </div>

                        </div>

                     

                         

                    </div>
                  
                </div>
         
            
    </div>

 



                </div>






                </div>


            </div>

        </div>

  


        </div>
    



        
    





      )

}

export default Savings