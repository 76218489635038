import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';

function Personal() {
      return(
        



        <div >

    





                                
              
        
    

    
    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/b1.png" style={{width: '100%'}} />
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_40_personal_s.jpg" alt="Personal" style={{width: '100%'}} />
        
        
    </div>

    <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="1" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0" class="active">
                            <Link to="/Personal">Personal</Link></li>
                    

            </ul>
        </div>
    </div>


    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                    


                    <div class="page_header">
                        <h1>
                            Personal</h1>

                    </div>
                    In the banking world, Ecofin is synonymous to personal touch. Though we have grown into a technologically advanced Bank, we take pride in boasting about our forte, i.e., traditional-customer friendly banking. Understanding our customer&rsquo;s needs and helping them to get rid of their worry has always been our priority.
                 




                    


    <div class="awards">
        <div class="row">
            
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <Link to='/savings'>
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/s1.png" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>At your service with the best savings account that offers highest interest on savings.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Savings</h5>
                                    </div>
                                </div>
                                    </Link>
                            </div>
                        
                    </div>
                
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <Link to='/termdeposit'>
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/Thumbnail_294_term-deposit_s.jpg" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>Interest at half-yearly, quarterly & monthly basis to make sure you receive more on your savings.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Term Deposit</h5>
                                    </div>
                                </div>
                                    </Link>
                            </div>
                        
                    </div>
                
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <Link to='/retailloan'>
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/Thumbnail_1_loans_s.jpg" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>EcoFin offers a wide range of Personal loans that will fulfill your every need.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Retail Loans</h5>
                                    </div>
                                </div>
                                    </Link>
                            </div>
                        
                    </div>
                
                   
                


        </div>
    </div>

                </div>




               


            </div>

        </div>

    </div>



    



        
    </div>





   
   
    






      )

}

export default Personal