import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';

function Fixeddeposit() {
      return(
        
       <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_294_term-deposit.jpg" style={{width: '100%'}}/>
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_294_term-deposit_s.jpg" alt="Term-Deposit" style={{width: '100%'}}/>
        
        
    </div>
   

      <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="3" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0">
                            <Link to='/personal'>Personal</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_1">
                            <Link to='/termdeposit'>Term Deposit</Link></li>
                    
                        <li id="MainContent_rptBreadcrumb_liItems_2" class="active">
                            <Link to='/fixeddeposit'>Fixed  Deposits</Link></li>
                    

            </ul>
        </div>
    </div>



    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                  
                    <div class="page_header">
                        <h1>
                            Fixed  Deposits</h1>

                    </div>
                    While your earning is in safe hands, we hope it grows securely. Choose EcoFin&rsquo;s Fixed Deposit schemes that come handy with suitable tenures and attractive interest rates.<br />
<br />
<div class="demat_benefits">
	<div class="demat_text">
		<h4>
			Fixed Deposits:</h4>
		<p>
			Savings has been an essential part of all the earnings, we at EcoFin, help you earn more on your savings by offering savings schemes which come with suitable tenures and attractive interest rates.</p>
	</div>
</div>
<br />
<div class="demat_benefits">
	<div class="demat_text">
		<h4>
			Term Deposits:</h4>
		<p>
			A term deposit is an Interest Payout savings scheme which offers interest at half yearly, quarterly and monthly basis.</p>
	</div>
</div>
<br />

<br />
<div class="demat_benefits">
	<div class="demat_text">
		<h4>
			Features &amp; Benefits:</h4>
		<ol>
			<li>
				TDS applicable Form 15H/15G required to be submitted for exemption of TDS.</li>
			<li>
				Nominal member of the bank will also be exempted from TDS. If you are exempt from paying tax, you need to present Form 15H when you open a Fixed Deposit and subsequently at the beginning of the following financial year.</li>
			<li>
				A minimum amount of Rs. 1000/- and a further rise in multiples of Rs. 100/- is required to open Term Deposits Account.</li>
			<li>
				Deposits under this scheme will be accepted for a period of above 12-months up to 36-months only.</li>
		</ol>
	</div>
</div>
<br />
<div class="demat_benefits">
	<div class="demat_text">
		<h4>
			Eligibility:</h4>
		<p>
			Individuals (singly or jointly), Minor by guardian, Organizations, Co-op. Societies.</p>
	</div>
</div>
<br />


                    

   


                </div>
            </div>
                </div>
            </div> 
            </div>

        




      )

}

export default Fixeddeposit