import React from 'react';
import { Routes, Route, Link,Redirect } from "react-router-dom";
import Personal from './Personal';
import Businessloan from'./Businessloan';


function Hcontent() {


return(

      
      
        <header class="header">
        
                <div class="wsmobileheader clearfix">
                    <a id="wsnavtoggle" class="animated-arrow"><span></span></a><a href="./index.html" class="smallogo">
                        <img src="./images/000.png" width="100" alt="" />
                    </a>
                 
                    <a class="callusicon" style={{size: '20px'}} target="_blank" 
                         href="">
                                            <h4>Mobile-Banking</h4></a>
                     
                    
                </div>







                



              
                <div class="container">
                    <div class="headtoppart clearfix">
                        <div class="headerwp">
                        

                            <div class="row">


                                <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">

                                    <div class="headertopleft">


                                   
                                        <div class="tollfree">
                                      
                                        
                                            <a href="tel:+91-91480 80555" id="tollfreea_main"><span>
                                           EcoFin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>


                                             Call us at : +91-91480 80555&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </a>

                                            
                                                    

                                                


                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div class="headertopright">
                                        <div class="net_banking">
                                            <div class="input-group">

						                    
	                                        <a id="btnLoginNew" class="btn input-group-addon" href="">
                                          Download App</a>
						
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>










                <div class="headerfull">
                    <div class="overlapblackbg"></div>
                    <div class="container">
                        <div class="wsmain">
                            <div class="smllogo">
                             <Link to="/index.html" id="logoLink">
                                    <img src="images/eco2.png" id="imgLogo" /></Link>
                            </div>
                            <span id="lbl"> <nav class="wsmenu clearfix"> <ul class="mobile-sub wsmenu-list">

                                <li class="rightmenu">
                                    
                                </li>
                                 
<li><a href="/"> <img src='/images/hm.jpg' class="img-rounds" alt=""  /> &nbsp;HOME</a></li>

<li><Link to="/personal"> &nbsp; &nbsp;Personal</Link>
   <ul class="wsmenu-submenu">
   <li><Link to='/savings'>Savings</Link></li>
    <li><Link to="/termdeposit">Term Deposit</Link></li>
    <li><Link to="/retailloan">Retail Loans</Link></li>
    
   </ul>
    
   </li>
       
   
  
   <li><Link to="/currentaccount">Current account</Link>
   <ul class="wsmenu-submenu">

  <li><Link to="/businessloans">Business Loans</Link></li>
 
   
   
  </ul>
  </li>
  
  
<li><Link to='/prepaidcards'>Prepaid Cards</Link>

</li>
 &nbsp;
<li><Link to="/mobilebanking">Mobile Banking</Link>

</li>
</ul>


</nav>

</span>
  </div>
                    </div>

                    


                </div>
               
            </header>
            
            

        
      
);
}

export default Hcontent;
