import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';


function CurrentAccount() {
      return(
        

        <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_40_personal.jpg" style={{width: '100%'}} />
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_40_personal_s.jpg" alt="Personal" style={{width: '100%'}} />
        
        
    </div>


<div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="1" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0" class="active">
                            <Link to='/currentaccount'>Current Account</Link></li>
                    

            </ul>
        </div>
    </div>




    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">


                    


                     <div class="page_header">
            <h1>Current Accounts</h1>
              
          </div>
          The thumb rule for successful business is to optimise time aptly. Acknowledging the importance of time in your life, we bring to you Current Accounts. Furnished with the finest services, Ecofin offers four distinct options to choose from, aimed at making your banking experience comfortable.






               <div class="compare_loans">


     

        
                <div class="compareloan_details">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="main_head">
                                <h4>Regular Current Account</h4>
                            


                               <div class="compare">
                                    <div class="checkbox">
                                       <input type="hidden" name="ctl00$ctl00$MainContent$innerContent$rptCompareLoans$ctl00$hidCompare" id="MainContent_innerContent_rptCompareLoans_hidCompare_0" value="1" />
                                      
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand1_0" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="compareloan_text" >
                                        <h5>Minimum Balance:</h5>
                                       
                                           <ol>
	<li>
		Minimum balance requirement of Rs 5000/- per quarter</li>
</ol>

                                         
                                    </div>
                                </div>
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand2_0" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                   <div class="compareloan_text" >
                                        <h5>Features:</h5>
                                       
                                           <ol>
	<li>
		Free Internet and Mobile Banking</li>
	<li>
		Free International Visa Debit Card for the 1st year</li>
	<li>
		Avail of Safe Deposit Locker facilities</li>
</ol>

                                         
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                
                            </div>

                        </div>

                     

                         

                    </div>
                  
                </div>
         
            
                <div class="compareloan_details">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="main_head">
                                <h4>Elite Current Account</h4>
                            

                               <div class="compare">
                                    <div class="checkbox">
                                       <input type="hidden" name="ctl00$ctl00$MainContent$innerContent$rptCompareLoans$ctl01$hidCompare" id="MainContent_innerContent_rptCompareLoans_hidCompare_1" value="1" />
                                      
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand1_1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="compareloan_text" >
                                        <h5>Minimum Balance:</h5>
                                       
                                           <ol>
	<li>
		Minimum quarterly average balance Rs. 10,000/-</li>
</ol>

                                         
                                    </div>
                                </div>
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand2_1" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                   <div class="compareloan_text" >
                                        <h5>Features:</h5>
                                       
                                           <ol>
	<li>
		Free Internet and Mobile Banking</li>
	<li>
		Deposit/Withdraw cash from any of our branches</li>
	<li>
		Free personalized cheque book with &#39;Payable at Par&#39; facility</li>
</ol>

                                         
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                
                            </div>

                        </div>

                     

                         

                    </div>
                  
                </div>
         
            
                <div class="compareloan_details">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="main_head">
                                <h4>Premium Current Account</h4>
                              

                               <div class="compare">
                                    <div class="checkbox">
                                       <input type="hidden" name="ctl00$ctl00$MainContent$innerContent$rptCompareLoans$ctl02$hidCompare" id="MainContent_innerContent_rptCompareLoans_hidCompare_2" value="1" />
                                      
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand1_2" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="compareloan_text" >
                                        <h5>Minimum Balance:</h5>
                                       
                                           <ol>
	<li>
		Minimum quarterly average balance&nbsp;Rs 50,000/-</li>
</ol>

                                         
                                    </div>
                                </div>
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand2_2" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                   <div class="compareloan_text" >
                                        <h5>Features:</h5>
                                       
                                           <ol>
	<li>
		Unlimited issuance of DD/PO</li>
	<li>
		Personalized cheque book free</li>
	<li>
		Free access to other bank ATMs</li>
</ol>

                                         
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                
                            </div>

                        </div>

                     

                         

                    </div>
                  
                </div>
         
            
                <div class="compareloan_details">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="main_head">
                                <h4>Platinum Current Account</h4>
                           

                               <div class="compare">
                                    <div class="checkbox">
                                       <input type="hidden" name="ctl00$ctl00$MainContent$innerContent$rptCompareLoans$ctl03$hidCompare" id="MainContent_innerContent_rptCompareLoans_hidCompare_3" value="1" />
                                      
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div class="row">
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand1_3" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="compareloan_text" >
                                        <h5>Minimum Balance:</h5>
                                       
                                           <ol>
	<li>
		Rs 3,00,000/- in Mumbai&nbsp;per quarter</li>
	<li>
		Rs 2,00,000/- outside Mumbai per quarter</li>
</ol>

                                         
                                    </div>
                                </div>
                                <div id="MainContent_innerContent_rptCompareLoans_DivMand2_3" class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                   <div class="compareloan_text" >
                                        <h5>Features:</h5>
                                       
                                           <ol>
	<li>
		No charges for giving standing instruction or solvency certificates</li>
	<li>
		Employees of the company are entitled to Akshay Salary Account</li>
	<li>
		Free personalized chequebook with &#39;Payable at Par&#39; facility</li>
</ol>

                                         
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                
                            </div>

                        </div>

                     

                         

                    </div>
                  
                </div>
         
            
    </div>

 



>
                </div>     











                </div>


            </div>

        </div>

    </div>



    



        
    





      )

}

export default CurrentAccount