import React from 'react';
import {Routes,Route,Link} from 'react-router-dom';

function Cards() {
      return(
        

        <div >

    





                                
              
        
    

    <div id="MainContent_dvpagebanner" class="banner">
        
        <img id="MainContent_imgBanner" class="img-responsive desktop_thumb" src="/images/Banner_294_term-deposit.jpg" style={{width: '100%'}}/>
        <img id="MainContent_imgThumbnail" class="img-responsive mobile_thumb" src="/images/Thumbnail_294_term-deposit_s.jpg" alt="Term-Deposit" style={{width: '100%'}}/>
        
        
    </div>
   


    <div class="page_breadcrumb">
        <div class="container">
            <ul class="breadcrumb">
                <input name="ctl00$ctl00$MainContent$hidTotalCount" type="hidden" id="MainContent_hidTotalCount" value="2" />
                <li><a href="index.html">Home</a></li>

                
                        <li id="MainContent_rptBreadcrumb_liItems_0"  class="active">
                            <Link to='/prepaidcards'>Cards</Link></li>
                    
                     

            </ul>
        </div>
    </div>
   


    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                 

                    <div class="page_header">
                        <h1>
                            Credit Cards</h1>

                    </div>
                    

                 


                    


    <div class="awards">
        <div class="row">
            
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <a href=''>
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/Thumbnail_480_Thumbnail_rupay-credit-card_s1.jpg" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">

                                        <p>EcoFin Credit Cards offers a host of benefits, offers & features to cater to your needs</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Credit Card</h5>
                                    </div>
                                </div>
                                    </a>
                            </div>
                        
                    </div>
                
                    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 animated" data-animation-delay="0.2s" data-animation="fadeIn">
                        
                            <div class="animated" data-animation-delay=".5s" data-animation="fadeIn">
                                
                                <a href=''>
                                <div class="ImageWrapper">
                                    <img class="img-responsive" src="/images/Thumbnail_479_Thumbnail_rupay-credit-card_s1%20-%202.jpg" />
                                    <div class="ImageOverlayH"></div>
                                    <div class="Buttons CStyleB">
                                        <p>EcoFin Credit Cards offers a host of benefits, offers & features to cater to your needs.</p>
                                        
                                        <label class="hover_effect know_more">KNOW MORE</label>

                                    </div>
                                    <div class="StyleNumber">
                                        <h5>Secured Credit Card</h5>
                                    </div>
                                </div>
                                    </a>
                            </div>
                        
                    </div>
                
                


        </div>
    </div>
 </div>










                </div>


            </div>

        </div>

    </div>



    



        
    





      )

}

export default Cards